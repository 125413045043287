import * as React from 'react';
import { IoShuffleOutline, IoPerson } from 'react-icons/io5';
// @mui
import { Box, Stack, Avatar as MuiAvatar } from '@mui/material';
// @ld
import { colors } from '@ld/utils';
import { Avatar, SubText } from '@ld/core';
// @custom
import useProfile from 'hooks/use-profile';
// @local
import { SwitchButton, IconWrapper } from './styles';
import ProfileSwitchModal from './profile-switch-modal';

const ProfileSwitch = () => {
  const { profile } = useProfile();
  const [open, setOpen] = React.useState(false);

  const handleOpen = React.useCallback(() => setOpen(true), []);
  const handleClose = React.useCallback(() => setOpen(false), []);

  return (
    <>
      <SwitchButton onClick={handleOpen}>
        <Stack direction='row' maxWidth='80%' spacing='10px' alignItems='center'>
          {profile ? (
            <Avatar
              alt={profile.name}
              src={profile.photoURL || ''}
              sx={{ width: 25, height: 25, fontSize: 12 }}
            />
          ) : (
            <MuiAvatar sx={{ width: 25, height: 25, fontSize: 12 }}>
              <IoPerson />
            </MuiAvatar>
          )}
          <Box maxWidth='80%'>
            <SubText noWrap>{profile ? profile.name : 'No profile selected'}</SubText>
            <SubText color={colors.green1}>Switch profile</SubText>
          </Box>
        </Stack>
        <IconWrapper>
          <IoShuffleOutline size={15} />
        </IconWrapper>
      </SwitchButton>
      <ProfileSwitchModal open={open} onClose={handleClose} />
    </>
  );
};

export default ProfileSwitch;

import * as React from 'react';
import { IconType } from 'react-icons';
import { IoMenuOutline, IoLogOutOutline } from 'react-icons/io5';
// @mui
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
// @ld
import { colors } from '@ld/utils';
import { UnstyledLink } from '@ld/core';
// @custom
import Logo from 'components/logo';
import { useSignOut } from 'api/auth';
import useProfile from 'hooks/use-profile';
import { getDashboardUrl } from 'utils/urls';
import { useFromApp } from 'hooks/use-from-app';
import ProfileSwitch from 'components/profile-switch';
import profileNavItems from 'components/sidebar/nav-items';
import DataSecurity from 'components/account-and-setting-modal/data-security';
import userAccountNavItems from 'components/account-and-setting-modal/nav-items';
// @local
import { LogoLink, DrawerContent } from './styles';

type NavListItemProps = {
  to: string;
  label: string;
  icon: IconType;
  onClick: () => void;
};

const NavListItem = ({ icon: Icon, label, to, onClick }: NavListItemProps) => (
  <UnstyledLink to={to}>
    <ListItem onClick={onClick}>
      <ListItemIcon>
        <Icon size={25} color={colors.black1} />
      </ListItemIcon>
      <ListItemText primary={label} sx={{ color: colors.black1 }} />
    </ListItem>
  </UnstyledLink>
);

const MobileAppBar = () => {
  const { profileId } = useProfile();
  const { handleSignOut } = useSignOut();
  const [open, setOpen] = React.useState(false);
  const { fromApp } = useFromApp();
  const dashboardUrl = getDashboardUrl(profileId);

  if (fromApp) {
    return null;
  }

  return (
    <>
      <AppBar position='fixed' color='transparent'>
        <Toolbar sx={{ height: 80 }}>
          <Stack
            width='100%'
            direction='row'
            alignItems='center'
            justifyContent='space-between'
          >
            <LogoLink to={dashboardUrl}>
              <Logo />
            </LogoLink>
            <IconButton onClick={() => setOpen(true)}>
              <IoMenuOutline color={colors.black1} size={30} />
            </IconButton>
          </Stack>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        open={open}
        anchor='right'
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <DrawerContent>
          <List
            component='nav'
            aria-labelledby='profile-navigation-list'
            subheader={
              <ListSubheader component='div' id='profile-navigation-list-subheader'>
                Profile
              </ListSubheader>
            }
          >
            <ListItem>
              <ProfileSwitch />
            </ListItem>
            {profileId && (
              <>
                {profileNavItems(profileId).map(({ inactiveIcon: icon, label, to }) => (
                  <NavListItem
                    to={to}
                    icon={icon}
                    label={label}
                    onClick={() => setOpen(false)}
                    key={`profile-navigation-${label.toLowerCase()}`}
                  />
                ))}
              </>
            )}
          </List>
          <List
            component='nav'
            aria-labelledby='account-navigation-list'
            subheader={
              <ListSubheader component='div' id='account-navigation-list-subheader'>
                Account & settings
              </ListSubheader>
            }
          >
            {userAccountNavItems.map((navProps) => (
              <NavListItem
                {...navProps}
                onClick={() => setOpen(false)}
                key={`account-navigation-${navProps.label.toLowerCase()}`}
              />
            ))}
            <ListItemButton onClick={() => handleSignOut()}>
              <ListItemIcon>
                <IoLogOutOutline size={25} color={colors.black1} />
              </ListItemIcon>
              <ListItemText primary='Sign out' sx={{ color: colors.black1 }} />
            </ListItemButton>
          </List>
          <Box p='20px 15px'>
            <DataSecurity />
          </Box>
        </DrawerContent>
      </SwipeableDrawer>
    </>
  );
};

export default MobileAppBar;

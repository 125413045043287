import * as React from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// @ld
import { IUserRead } from '@ld/utils';

// @custom
import useAuth from 'hooks/use-auth';
import UserContext from 'context/user-context';
import { useSetFromApp } from 'hooks/use-from-app';
import { ProfileProvider } from 'context/profile-context';
import { SnackbarProvider } from 'context/snackbar-context';
import { ProfileRoleProvider } from 'context/profile-role-context';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

type AppProviderProps = {
  children: React.ReactNode;
};

const AppProvider = ({ children }: AppProviderProps) => {
  const { user, isInitialising, setUser } = useAuth();

  useSetFromApp();

  const updateUser = React.useCallback(
    (data: Partial<IUserRead>) => {
      if (user) {
        setUser({ ...user, ...data });
      }
    },
    [user]
  );

  if (isInitialising) {
    return <div>Loading...</div>;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider>
        <UserContext.Provider
          value={{
            user,
            updateUser,
            setUser: (newUser: IUserRead | null) => setUser(newUser)
          }}
        >
          <ProfileProvider>
            <ProfileRoleProvider>{children}</ProfileRoleProvider>
          </ProfileProvider>
        </UserContext.Provider>
      </SnackbarProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default AppProvider;

import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// @ld
import { PageLoader, GlobalStyles, ThemeProvider, ErrorFallback } from '@ld/core';
// @custom
import * as urls from 'constants/urls';
import Segment from 'components/segment';
import PageNotFound from 'pages/page-not-found';
import AppProvider from 'components/app-provider';
import AppIdleTimer from 'components/app-idle-timer';
import DelightedSurvey from 'components/delighted-survey';
import StagingEnvBanner from 'components/staging-env-banner';
import AppLayoutRoute from 'components/layouts/app-layout-route';
import AuthLayoutRoute from 'components/layouts/auth-layout-route';
import BlankLayoutRoute from 'components/layouts/blank-layout-route';

const App = () => {
  return (
    <ThemeProvider>
      <GlobalStyles />
      <Router>
        <AppProvider>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <React.Suspense fallback={<PageLoader />}>
              <Switch>
                <AuthLayoutRoute
                  exact
                  path='/'
                  component={React.lazy(
                    () => import(/* webpackChunkName: 'Signin' */ './pages/auth/signin')
                  )}
                />
                <AuthLayoutRoute
                  exact
                  path={urls.twoFactorAuthenticationUrl}
                  component={React.lazy(
                    () =>
                      import(/* webpackChunkName: 'Signin' */ './pages/auth/two-factor')
                  )}
                />
                <AuthLayoutRoute
                  exact
                  path={urls.forgotPasswordUrl}
                  component={React.lazy(
                    () =>
                      import(
                        /* webpackChunkName: 'ForgotPassword' */ './pages/auth/forgot-password'
                      )
                  )}
                />
                <AuthLayoutRoute
                  exact
                  path={urls.createAccountUrl}
                  component={React.lazy(
                    () =>
                      import(
                        /* webpackChunkName: 'CreateAnAccount' */ './pages/auth/create-an-account'
                      )
                  )}
                />
                <AuthLayoutRoute
                  exact
                  path={urls.createAccountSuccessUrl}
                  component={React.lazy(
                    () =>
                      import(
                        /* webpackChunkName: 'CreateAccountSuccess' */ './pages/auth/create-account-success'
                      )
                  )}
                />
                <AuthLayoutRoute
                  exact
                  path={urls.verifyEmailUrl}
                  component={React.lazy(
                    () =>
                      import(
                        /* webpackChunkName: 'VerifyEmail' */ './pages/auth/verify-email'
                      )
                  )}
                />
                <AuthLayoutRoute
                  exact
                  path={urls.authActionUrl}
                  component={React.lazy(
                    () =>
                      import(/* webpackChunkName: 'AuthAction' */ './pages/auth/action')
                  )}
                />
                <AuthLayoutRoute
                  exact
                  path={urls.lockAccountUrl}
                  component={React.lazy(
                    () =>
                      import(
                        /* webpackChunkName: 'LockAccount' */ './pages/auth/lock-account'
                      )
                  )}
                />
                <AuthLayoutRoute
                  exact
                  path={urls.lockedAccountUrl}
                  component={React.lazy(
                    () =>
                      import(
                        /* webpackChunkName: 'LockedAccount' */ './pages/auth/locked-account'
                      )
                  )}
                />
                <AuthLayoutRoute
                  exact
                  path={urls.unauthorizedUrl}
                  component={React.lazy(
                    () =>
                      import(
                        /* webpackChunkName: 'Unauthorized' */ './pages/auth/unauthorized'
                      )
                  )}
                />
                <AuthLayoutRoute
                  exact
                  path={urls.shareUrl}
                  component={React.lazy(
                    () => import(/* webpackChunkName: 'Share' */ './pages/share')
                  )}
                />
                <BlankLayoutRoute
                  exact
                  path={urls.onboardUrl}
                  component={React.lazy(
                    () => import(/* webpackChunkName: 'Onboard' */ './pages/onboard')
                  )}
                />
                <Route
                  path={urls.profileUrl}
                  component={React.lazy(
                    () =>
                      import(/* webpackChunkName: 'Profile' */ './pages/profile/router')
                  )}
                />
                <AppLayoutRoute
                  path={urls.accountUrl}
                  component={React.lazy(
                    () => import(/* webpackChunkName: 'Account' */ './pages/account')
                  )}
                />
                <Route path='*' component={PageNotFound} />
              </Switch>
            </React.Suspense>
          </ErrorBoundary>
          <Segment />
          <AppIdleTimer />
          <DelightedSurvey />
          <StagingEnvBanner />
        </AppProvider>
      </Router>
    </ThemeProvider>
  );
};

export default App;

import { IoAddCircleOutline } from 'react-icons/io5';
// @mui
import { List, Button } from '@mui/material';
// @ld
import { UnstyledLink } from '@ld/core';
import { IProfileRead } from '@ld/utils';
// @custom
import useUser from 'hooks/use-user';
import { myProfilesUrl } from 'constants/urls';
import useGetItems from 'api/utils/use-get-items';
import SkeletonList from 'components/skeleton-list';
import { myProfilesQuery, myProfilesQueryKey } from 'api/profiles';
import sortByCreatedTimestamp from 'utils/sort-by-created-timestamp';
// @local
import ProfileNavItem, { ProfileNavItemSkeleton } from './profile-nav-item';

const MyProfilesList = ({ onClose }: { onClose: () => void }) => {
  const { user } = useUser();
  const {
    isError,
    isLoading,
    data: profiles
  } = useGetItems<IProfileRead>({
    query: myProfilesQuery(),
    queryKey: myProfilesQueryKey(),
    select: sortByCreatedTimestamp
  });

  if (isLoading) {
    return <SkeletonList id='my-profiles' count={2} skeleton={ProfileNavItemSkeleton} />;
  }

  if (isError) {
    return <p>Couldn&apos;t load profiles</p>;
  }

  return (
    <List onClick={onClose}>
      {profiles && profiles.length === 0 ? (
        <>
          <p>
            You don&apos;t have any profiles yet. Add your first profile to get started.
          </p>
          <UnstyledLink to={myProfilesUrl}>
            <Button
              sx={{
                p: 0,
                mt: '20px',
                fontWeight: 'normal',
                ':hover': { background: 'transparent' }
              }}
              variant='text'
              color='secondary'
              startIcon={<IoAddCircleOutline />}
            >
              Add profile
            </Button>
          </UnstyledLink>
        </>
      ) : (
        profiles?.map((profile) => (
          <ProfileNavItem
            key={profile.id}
            profile={profile}
            isPrimaryProfile={user?.primaryProfileId === profile.id}
          />
        ))
      )}
    </List>
  );
};

export default MyProfilesList;

import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

// @custom
import { isDebugEnv, isLocalEnv, recaptchaKey, appCheckDebugToken } from 'config';

const clientCredentials = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

const app = initializeApp(clientCredentials);

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
if (recaptchaKey) {
  if ((isDebugEnv || isLocalEnv) && appCheckDebugToken) {
    (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = appCheckDebugToken; // eslint-disable-line no-restricted-globals
  }
  initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(recaptchaKey),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
  });
}

if (isLocalEnv) {
  connectFirestoreEmulator(getFirestore(), 'localhost', 8080);
  connectAuthEmulator(getAuth(), 'http://localhost:9099');
  const functions = getFunctions(app, 'europe-west6');
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectStorageEmulator(getStorage(), 'localhost', 9199);
}

import { addDoc, FirestoreError } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
// @custom
import getEventAt from 'utils/get-event-at';
// @local
import { getCollectionRef } from './refs';

const useCreateItem = <TDataWrite, TDataRead>(
  collectionName: string,
  options?: UseMutationOptions<
    TDataRead,
    FirestoreError,
    Omit<TDataWrite, 'created' | 'updated'>
  >
) => {
  const { mutate: handleCreate, ...rest } = useMutation((data) => {
    const user = getAuth().currentUser;
    if (!user) {
      return Promise.reject('Only authenticated users can perform this action');
    }

    const eventAt = getEventAt({ uid: user.uid, name: user.displayName || '' });

    const collectionRef = getCollectionRef(collectionName);
    return addDoc(collectionRef, {
      ...data,
      created: eventAt.write,
      updated: eventAt.write
    }).then(
      (docRef) =>
        ({
          ...data,
          id: docRef.id,
          created: eventAt.read,
          updated: eventAt.read
        } as TDataRead)
    );
  }, options);
  return { handleCreate, ...rest };
};

export default useCreateItem;

import {
  doc,
  query,
  where,
  collection,
  writeBatch,
  getFirestore,
  FirestoreError
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import useUser from 'hooks/use-user';
import getEventAt from 'utils/get-event-at';

import { UseMutationOptions, useMutation } from '@tanstack/react-query';
// @ld
import {
  USERS,
  PROFILES,
  EProfileType,
  IProfileRead,
  IProfileWrite,
  EProfileStatus,
  TNullableString
} from '@ld/utils';
// @custom

// @local
import useCreateItem from '../utils/use-create-item';
import { itemQueryKey } from '../utils/use-get-item';
import { getCollectionRef, getDocRef } from '../utils/refs';

export const profileQueryKey = (id?: string) => itemQueryKey(PROFILES, id);

export type TCreatePrimaryProfileData = {
  paymentMethodId: string;
  promoCode: TNullableString;
};

export const useCreatePrimaryProfile = (
  options?: UseMutationOptions<string, FirestoreError, TCreatePrimaryProfileData>
) => {
  const { user } = useUser();
  const { mutate: handleCreatePrimaryProfile, ...rest } = useMutation((data) => {
    const db = getFirestore();
    if (user) {
      // Get a new write batch
      const batch = writeBatch(db);

      // create primary profile
      // Add a new document with a generated id
      const newProfileRef = doc(collection(db, PROFILES));
      const eventAt = getEventAt(user).write;
      const profile: IProfileWrite = {
        ...data,
        paymentMethodId: 'TEST',
        name: user.name,
        countryCode: 'za',
        isVaultSetup: false,
        suspendedReason: null,
        ownerUserId: user.uid,
        billingUserId: user.uid,
        estateReadinessScore: 0,
        estateReadinessGap: null,
        estateReadinessGapCount: 0,
        type: EProfileType.PERSONAL,
        billingOrganisationId: null,
        access: { [user.uid]: true },
        status: EProfileStatus.ACTIVE,
        photoURL: user.photoURL || null,
        email: user.email.trim().toLowerCase(),
        created: eventAt,
        updated: eventAt
      };
      batch.set(newProfileRef, profile);

      // user doc
      const userRef = getDocRef(USERS, user.uid);
      batch.set(
        userRef,
        {
          primaryProfileId: newProfileRef.id,
          updated: eventAt
        },
        { merge: true }
      );

      // Commit the batch
      return batch.commit().then(() => newProfileRef.id);
    }
    return Promise.reject('No authenticated user');
  }, options);
  return { handleCreatePrimaryProfile, ...rest };
};

export const useCreateProfile = (
  options?: UseMutationOptions<
    IProfileRead,
    FirestoreError,
    Omit<IProfileWrite, 'created' | 'updated'>
  >
) => {
  const { handleCreate, ...rest } = useCreateItem<IProfileWrite, IProfileRead>(
    PROFILES,
    options
  );
  const handleCreateProfile = ({
    email,
    ...rest
  }: {
    name: string;
    email: TNullableString;
    status: EProfileStatus;
    paymentMethodId: string;
    promoCode: string | null;
  }) => {
    const user = getAuth().currentUser;
    if (user) {
      handleCreate({
        ...rest,
        photoURL: null,
        countryCode: 'za',
        isVaultSetup: false,
        suspendedReason: null,
        ownerUserId: user.uid,
        billingUserId: user.uid,
        estateReadinessScore: 0,
        estateReadinessGap: null,
        estateReadinessGapCount: 0,
        type: EProfileType.PERSONAL,
        billingOrganisationId: null,
        access: { [user.uid]: true },
        email: email?.trim()?.toLowerCase() || null
      });
    }
  };
  return { handleCreateProfile, ...rest };
};

export const myProfilesQueryKey = () => ['my-profiles'];

export const myProfilesQuery = () => {
  const user = getAuth().currentUser;
  return query(getCollectionRef(PROFILES), where('ownerUserId', '==', user?.uid));
};

export const sharedProfilesQueryKey = () => ['shared-profiles'];

export const sharedProfilesQuery = () => {
  const user = getAuth().currentUser;
  return query(getCollectionRef(PROFILES), where(`access.${user?.uid}`, '==', true));
};

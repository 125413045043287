import { config } from '@ld/core';

type VaultCategoryParams = {
  profileId?: string;
  categoryId?: string;
};

const getProfileBaseUrl = (profileId = '') => `/profile/${profileId}`;

export const getDashboardUrl = (profileId = '') =>
  `${getProfileBaseUrl(profileId)}/dashboard`;

export const getContactsUrl = (profileId = '') =>
  `${getProfileBaseUrl(profileId)}/contacts`;

export const getContactUrl = ({
  profileId,
  contactId
}: {
  profileId: string;
  contactId: string;
}) => `${getContactsUrl(profileId)}/${contactId}`;

export const getVaultUrl = (profileId = '') => `${getProfileBaseUrl(profileId)}/vault`;

export const getVaultSetupUrl = (profileId = '') => `${getVaultUrl(profileId)}/setup`;

export const getVaultManagerUrl = (profileId = '') => `${getVaultUrl(profileId)}/manage`;

export const getCategoryUrl = ({ profileId, categoryId }: VaultCategoryParams) =>
  `${getVaultUrl(profileId)}/c/${categoryId}`;

export const getManageCategoryUrl = (params: VaultCategoryParams) =>
  `${getCategoryUrl(params)}/manage`;

export const getCategoryDocTypeUrl = ({
  type,
  ...rest
}: VaultCategoryParams & { type?: string }) => `${getCategoryUrl(rest)}/t/${type}`;

type EntityUrlParams = VaultCategoryParams & { entityId?: string };

export const getEntityUrl = ({ entityId, ...rest }: EntityUrlParams) =>
  `${getCategoryUrl(rest)}/e/${entityId}`;

export const getManageEntityUrl = (params: EntityUrlParams) =>
  `${getEntityUrl(params)}/manage`;

type EntityDocTypeUrlParams = EntityUrlParams & { type?: string };

export const getEntityDocTypeUrl = ({ type, ...rest }: EntityDocTypeUrlParams) =>
  `${getEntityUrl(rest)}/t/${type}`;

export const getDocumentTypeUrl = (params: EntityDocTypeUrlParams) => {
  if (params.entityId) {
    return getEntityDocTypeUrl(params);
  }
  return getCategoryDocTypeUrl(params);
};

type DocumentParams = EntityDocTypeUrlParams & {
  id: string;
  multiple?: boolean;
};

export const getDocumentUrl = ({
  id,
  type,
  entityId,
  multiple = false,
  ...baseParams
}: DocumentParams) => {
  let baseUrl = '';
  if (multiple) {
    baseUrl = getDocumentTypeUrl({ ...baseParams, type });
  } else if (entityId) {
    baseUrl = getEntityUrl({ ...baseParams, entityId });
  } else {
    baseUrl = getCategoryUrl(baseParams);
  }

  return `${baseUrl}/d/${id}`;
};

export const getShareLinkUrl = (id: string) => `${config.clientPortalUrl}/share/${id}`;

import { getDoc, FirestoreError, DocumentData } from 'firebase/firestore';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
// @local
import { getDocRef } from './refs';

export const itemQueryKey = (collection: string, id?: string) => [collection, id];

export const itemQueryFn =
  <TData>(collection: string, id = '') =>
  () => {
    const ref = getDocRef(collection, id);
    return getDoc(ref).then((doc) => {
      if (doc.exists()) {
        return { id: doc.id, ...(doc.data() as DocumentData) } as TData;
      }
      return undefined;
    });
  };

type UseGetItem<T> = {
  id?: string;
  collection: string;
} & UseQueryOptions<T | undefined, FirestoreError>;

const useGetItem = <TData>({ id, collection, ...rest }: UseGetItem<TData>) => {
  return useQuery<TData | undefined, FirestoreError>({
    ...rest,
    queryKey: itemQueryKey(collection, id),
    queryFn: itemQueryFn<TData>(collection, id)
  });
};

export default useGetItem;

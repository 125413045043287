// @mui
import List from '@mui/material/List';
// @ld
import { IProfileRead } from '@ld/utils';
// @custom
import useGetItems from 'api/utils/use-get-items';
import SkeletonList from 'components/skeleton-list';
import sortByCreatedTimestamp from 'utils/sort-by-created-timestamp';
import { sharedProfilesQuery, sharedProfilesQueryKey } from 'api/profiles';
// @local
import ProfileNavItem, { ProfileNavItemSkeleton } from './profile-nav-item';

const SharedProfilesList = ({ onClose }: { onClose: () => void }) => {
  const {
    isError,
    isLoading,
    data: profiles
  } = useGetItems<IProfileRead>({
    query: sharedProfilesQuery(),
    queryKey: sharedProfilesQueryKey(),
    select: sortByCreatedTimestamp
  });

  if (isLoading) {
    return (
      <SkeletonList id='shared-profiles' count={2} skeleton={ProfileNavItemSkeleton} />
    );
  }

  if (isError) {
    return <p>Couldn&apos;t load profiles</p>;
  }

  return (
    <List onClick={onClose}>
      {profiles && profiles.length === 0 ? (
        <p>There are no profiles shared with you</p>
      ) : (
        profiles?.map((profile) => <ProfileNavItem key={profile.id} profile={profile} />)
      )}
    </List>
  );
};

export default SharedProfilesList;

import * as React from 'react';
import { IoPersonCircleOutline } from 'react-icons/io5';
// @mui
import { Box, Button, Badge } from '@mui/material';
// @ld
import { config, UnstyledLink } from '@ld/core';
import { colors, IOrganisationRead, ORGANISATIONS } from '@ld/utils';
// @custom
import Logo from 'components/logo';
import useUser from 'hooks/use-user';
import useProfile from 'hooks/use-profile';
import { getDashboardUrl } from 'utils/urls';
import useGetItem from 'api/utils/use-get-item';
import useProfileRole from 'hooks/use-profile-role';
import ProfileSwitch from 'components/profile-switch';
import AccountAndSettingsModal from 'components/account-and-setting-modal';
// local
import profileNavItems from './nav-items';
import NavItem from './components/nav-item';
import AddProfile from './components/add-profile';
import DisabledNavItem from './components/disabled-nav-item';
import { ASide, PoweredByLink, OrganisationLogo } from './styles';

const Sidebar = () => {
  const { user } = useUser();
  const { profile, profileId } = useProfile();
  const { role: profileRole } = useProfileRole();
  const [openAccountModal, setOpenAccountModal] = React.useState(false);

  const { data: org } = useGetItem<IOrganisationRead>({
    collection: ORGANISATIONS,
    id: profile?.billingOrganisationId ?? '',
    enabled: Boolean(profile?.billingOrganisationId)
  });

  const canClickNav = (label: string) => {
    if (profile?.ownerUserId !== user?.uid) {
      if (label === 'Contacts' && !profileRole?.contacts) {
        return false;
      }
      if (label === 'Dashboard' && !profileRole?.dashboard) {
        return false;
      }
    }
    return true;
  };
  return (
    <>
      <ASide>
        <Box p='30px'>
          <UnstyledLink to={getDashboardUrl(profileId)}>
            {org ? <OrganisationLogo src={org.logoURL || ''} alt={org.name} /> : <Logo />}
          </UnstyledLink>
          {org && (
            <PoweredByLink href={config.websiteUrl} target='_blank'>
              Powered by
              <Logo height={15} />
            </PoweredByLink>
          )}
        </Box>
        <Box p='10px'>
          <ProfileSwitch />
        </Box>
        <Box component='nav' flex={1}>
          {!user?.primaryProfileId && !profile ? (
            <AddProfile />
          ) : (
            profileNavItems(profileId).map((navProps) =>
              canClickNav(navProps.label) ? (
                <NavItem key={`nav-item-${navProps.label}`} {...navProps} />
              ) : (
                <DisabledNavItem
                  key={`disabled-nav-item-${navProps.label}`}
                  {...navProps}
                />
              )
            )
          )}
        </Box>
        <Box px='10px' pb='20px'>
          <Button
            fullWidth
            size='small'
            color='inherit'
            onClick={() => setOpenAccountModal(true)}
            startIcon={<IoPersonCircleOutline size={25} />}
            endIcon={<Badge color='primary' badgeContent={user?.profileInvitesCount} />}
            sx={{
              background: colors.grey5,
              justifyContent: 'flex-start',
              '&:hover': { background: colors.grey4 },
              '.MuiButton-endIcon': { right: '25px', position: 'absolute' }
            }}
          >
            Account
          </Button>
        </Box>
      </ASide>
      <AccountAndSettingsModal
        open={openAccountModal}
        onClose={() => setOpenAccountModal(false)}
      />
    </>
  );
};

export default Sidebar;

import * as React from 'react';
import { IoPeopleCircleOutline } from 'react-icons/io5';
// @mui
import { Box, Divider, Button } from '@mui/material';
// @ld
import { Modal, UnstyledLink } from '@ld/core';
// @custom
import { myProfilesUrl } from 'constants/urls';
import { ToggleButtonGroup } from 'components/toggle-button-group';
// @local
import MyProfilesList from './my-profiles-list';
import SharedProfilesList from './shared-profiles-list';

type TProfileSwitchModalProps = { open: boolean; onClose: () => void };

const ProfileSwitchModal = ({ open, onClose }: TProfileSwitchModalProps) => {
  const [view, setView] = React.useState<string>('my-profiles');

  const handleView = (_event: React.MouseEvent<HTMLElement>, newView: string) => {
    if (newView) {
      setView(newView);
    }
  };

  return (
    <Modal open={open} onClose={onClose} id='switch-profile' title='Switch profile'>
      <ToggleButtonGroup
        fullWidth
        exclusive
        value={view}
        onChange={handleView}
        options={[
          {
            value: 'my-profiles',
            label: 'My profiles'
          },
          {
            value: 'shared-with-me',
            label: 'Shared with me'
          }
        ]}
      />
      <Box pt='10px'>
        {view === 'my-profiles' && <MyProfilesList onClose={onClose} />}
        {view === 'shared-with-me' && <SharedProfilesList onClose={onClose} />}
      </Box>
      {view === 'my-profiles' && (
        <>
          <Divider sx={{ my: '10px' }} />
          <UnstyledLink to={myProfilesUrl}>
            <Button
              fullWidth
              variant='text'
              color='secondary'
              onClick={onClose}
              sx={{
                paddingX: 0,
                justifyContent: 'flex-start',
                '&:hover': { background: 'transparent' }
              }}
              startIcon={<IoPeopleCircleOutline size={24} />}
            >
              Manage profiles
            </Button>
          </UnstyledLink>
        </>
      )}
    </Modal>
  );
};

export default ProfileSwitchModal;

import qs from 'query-string';
import React, { Suspense } from 'react';
import { Route, RouteProps, useLocation } from 'react-router-dom';
// @mui
import Box from '@mui/material/Box';
// @ld
import { Loader, config } from '@ld/core';
// @custom
import Logo from '../logo';
import Footer from '../footer';
import useRedirectToApp from './use-redirect-to-app';
import {
  AuthContainer,
  ShapesBackground,
  Main,
  Card,
  PoweredByLink,
  LinkCard
} from './styles';

const AuthLayout = ({ children }: { children: React.ReactNode }) => {
  const { search } = useLocation();
  const { organisation } = qs.parse(search);

  return (
    <AuthContainer>
      <Main $isCentered>
        {organisation ? (
          <img
            src={`https://ld-public-assets.s3.eu-west-2.amazonaws.com/logos/${organisation}.png`}
            alt='Organisation Logo'
            width={180}
          />
        ) : (
          <Logo height={50} />
        )}
        <Card>{children}</Card>
      </Main>
      {organisation ? (
        <LinkCard>
          <PoweredByLink href={config.websiteUrl} target='_blank'>
            Powered by
            <Logo width={120} height={15} />
          </PoweredByLink>
        </LinkCard>
      ) : (
        <Footer />
      )}
      <ShapesBackground />
    </AuthContainer>
  );
};

const Fallback = () => (
  <Box display='flex' alignItems='center' justifyContent='center' p={3}>
    <Loader />
  </Box>
);

const AuthLayoutRoute = ({
  component: Component,
  children,
  path,
  ...rest
}: RouteProps & { children?: React.ReactNode }) => {
  useRedirectToApp();
  return (
    <Route
      {...rest}
      render={(props) => (
        <AuthLayout>
          <Suspense fallback={<Fallback />}>
            {Component ? <Component {...props} /> : children}
          </Suspense>
        </AuthLayout>
      )}
    />
  );
};

export default AuthLayoutRoute;

import * as React from 'react';
import { IconType } from 'react-icons';
import * as Icons from 'react-icons/io5';
// @mui
import { Box, Stack, Badge, Button, IconButton, DialogProps } from '@mui/material';
// @ld
import { colors } from '@ld/utils';
import { Avatar, Heading3, Heading4, BodySmallText, UnstyledLink } from '@ld/core';
// @custom
import useUser from 'hooks/use-user';
import { useSignOut } from 'api/auth';
// @local
import navItems from './nav-items';
import DataSecurity from './data-security';
import { Dialog, DialogContent } from './styles';
import { profileInvitesUrl } from 'constants/urls';

type TAccountAndSettingsModal = { onClose: () => void } & DialogProps;

type TNavItem = {
  to: string;
  label: string;
  icon: IconType;
  onClick: () => void;
  badgeContent?: React.ReactNode;
};

const NavItem = ({ to, label, icon: Icon, onClick, badgeContent }: TNavItem) => (
  <Stack
    to={to}
    pb='20px'
    spacing='10px'
    direction='row'
    onClick={onClick}
    component={UnstyledLink}
  >
    <Icon color={colors.black1} size={25} />
    <BodySmallText>{label}</BodySmallText>
    <Badge color='primary' badgeContent={badgeContent} />
  </Stack>
);

const AccountAndSettingsModal = ({ onClose, ...rest }: TAccountAndSettingsModal) => {
  const { user } = useUser();
  const { handleSignOut } = useSignOut();
  return (
    <Dialog {...rest} fullWidth maxWidth='xs' onClose={onClose}>
      <Stack
        p='20px'
        direction='row'
        alignItems='flex-start'
        justifyContent='space-between'
      >
        <Stack direction='row' alignItems='center' spacing={2}>
          <Avatar alt={user?.name || ''} sx={{ width: 60, height: 60 }} />
          <Box width='80%'>
            <Heading3>{user?.name}</Heading3>
            <BodySmallText noWrap>{user?.email}</BodySmallText>
          </Box>
        </Stack>
        <IconButton size='small' sx={{ background: colors.grey4 }} onClick={onClose}>
          <Icons.IoCloseOutline size={16} />
        </IconButton>
      </Stack>
      <DialogContent>
        <Heading4>Account & settings</Heading4>
        <Box pt='20px' component='nav' aria-label='navigation'>
          {navItems.map((navProps, index) => (
            <NavItem
              {...navProps}
              onClick={onClose}
              key={`account-settings-nav-item-${index}`}
              badgeContent={
                navProps.to === profileInvitesUrl ? user?.profileInvitesCount : undefined
              }
            />
          ))}
          <Button
            fullWidth
            variant='text'
            color='inherit'
            onClick={() => handleSignOut()}
            sx={{
              padding: '0px 0px 0px 6px',
              justifyContent: 'flex-start',
              '&:hover': { background: 'transparent' }
            }}
            startIcon={<Icons.IoLogOutOutline color={colors.black1} size={25} />}
          >
            <BodySmallText>Sign out</BodySmallText>
          </Button>
        </Box>
      </DialogContent>
      <Box p='20px'>
        <DataSecurity />
      </Box>
    </Dialog>
  );
};

export default AccountAndSettingsModal;

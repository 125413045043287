import * as React from 'react';
// @ld
import { IProfileRead, PROFILES } from '@ld/utils';
// @custom
import useUser from 'hooks/use-user';
import useDocListener from 'api/utils/use-doc-listener';

const ProfileContext = React.createContext<{
  isError: boolean;
  profileId: string;
  profile?: IProfileRead;
  setProfileId: (profileId?: string) => void;
}>({ profileId: '', isError: false, setProfileId: () => null });

export const ProfileProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useUser();
  const [profileId, setProfileId] = React.useState<string>();

  const { data: profile, isError } = useDocListener<IProfileRead>({
    id: profileId,
    collection: PROFILES,
    enabled: !!profileId
  });

  React.useEffect(() => {
    if (!profileId && user?.primaryProfileId) {
      setProfileId(user.primaryProfileId);
    }
  }, [profileId]);

  return (
    <ProfileContext.Provider
      value={{
        isError,
        profile,
        setProfileId,
        profileId: profileId || ''
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

ProfileContext.displayName = 'ProfileContext';

export default ProfileContext;

import { IconType } from 'react-icons';
import { NavLinkProps } from 'react-router-dom';

// @custom
import { StyledDisabledLink, IconWrapper } from './styles';

const StyledDisabledNavLink = ({ children, ...rest }: NavLinkProps) => (
  <StyledDisabledLink {...rest} activeClassName='active'>
    {children}
  </StyledDisabledLink>
);

type NavItemProps = {
  to: string;
  label: string;
  activeIcon: IconType;
  inactiveIcon: IconType;
};

const DisabledNavItem = ({
  to,
  label,
  activeIcon: ActiveIcon,
  inactiveIcon: InactiveIcon
}: NavItemProps) => (
  <StyledDisabledNavLink to={to}>
    <IconWrapper>
      <InactiveIcon size={24} />
      <ActiveIcon size={24} />
    </IconWrapper>
    {label}
  </StyledDisabledNavLink>
);

export default DisabledNavItem;
